// src/pages/AdminDashboard.js

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

// Chart.js imports
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2'; // Chart.js component

// Register the necessary components with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function AdminDashboard() {
  const navigate = useNavigate();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [user, setUser] = useState(null);

  // Statistics data
  const [totalUploaded, setTotalUploaded] = useState(0);
  const [totalProcessed, setTotalProcessed] = useState(0);
  const [totalImagesInUploadFolder, setTotalImagesInUploadFolder] = useState(0);

  // Settings data
  const [settings, setSettings] = useState({
    backgroundColor: '#f8f9fa',
    buttonColor: '#d63384',
    titleColor: '#333',
    heroTitle: 'Welcome to Our Premium Photo Upload Experience',
    heroSubtitle: 'Easily upload and share your favorite moments with just a few clicks.',
  });

  // Authentication function moved to useCallback to ensure proper dependency handling
  const authenticateUser = useCallback(async () => {
    try {
      const response = await axios.get('/api/user/profile');
      if (response.status === 200) {
        setUser(response.data);
        loadGalleryImages();
        loadUploadsCount(); // Load initial total count of uploaded images in uploads folder
        loadSettings(); // Load initial settings
      }
    } catch (error) {
      console.error('User not authenticated:', error);
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    // Check if user is authenticated, otherwise redirect to login
    authenticateUser();
  }, [authenticateUser]);

  const loadGalleryImages = async () => {
    try {
      const response = await axios.get('/api/gallery/data');
      if (response.status === 200) {
        setUploadedImages(response.data.uploadedImages);
        setTotalUploaded(response.data.uploadedImages.length);
        setTotalProcessed(response.data.processedImages.length);
      }
    } catch (error) {
      console.error('Failed to fetch gallery data:', error);
    }
  };

  const loadUploadsCount = async () => {
    try {
      const response = await axios.get('/api/uploads/count');
      if (response.status === 200) {
        setTotalImagesInUploadFolder(response.data.count);
      }
    } catch (error) {
      console.error('Failed to fetch uploads count:', error);
    }
  };

  const loadSettings = async () => {
    try {
      const response = await axios.get('/api/settings');
      if (response.status === 200) {
        setSettings({
          backgroundColor: response.data.backgroundColor || '#f8f9fa',
          buttonColor: response.data.buttonColor || '#d63384',
          titleColor: response.data.titleColor || '#333',
          heroTitle: response.data.heroTitle || 'Welcome to Our Premium Photo Upload Experience',
          heroSubtitle: response.data.heroSubtitle || 'Easily upload and share your favorite moments with just a few clicks.',
        });
      }
    } catch (error) {
      console.error('Failed to load settings:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.get('/api/logout');
      navigate('/'); // Redirect to home page after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Setup socket connection to update the gallery for new images
  useEffect(() => {
    const socket = io('/gallery'); // Connect to the '/gallery' namespace

    socket.on('newImageUploaded', ({ imageUrl }) => {
      setUploadedImages((prevImages) => {
        // Add the new image to the start and maintain only the latest 5 images
        const updatedImages = [imageUrl, ...prevImages];
        return updatedImages.slice(0, 5);
      });
      setTotalUploaded((prev) => prev + 1);
      loadUploadsCount(); // Update the total images in the uploads folder
    });

    // Cleanup the socket connection
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    document.title = 'MazenDev Admin';
  }, []);

  // Chart Data
  const data = {
    labels: ['Uploaded Images', 'Processed Images'],
    datasets: [
      {
        label: '# of Images',
        data: [totalUploaded, totalProcessed],
        backgroundColor: [settings.buttonColor, '#28a745'],
      },
    ],
  };

  return (
    <div className="admin-dashboard-container" style={{ background: settings.backgroundColor }}>
      {/* Navigation Bar */}
      <nav className="navbar navbar-light bg-light shadow-sm mb-4">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <button className="btn btn-danger logout-button" onClick={handleLogout}>
            Logout
          </button>
          <div className="d-flex align-items-center ms-auto">
            <span className="fw-bold me-2">{user?.name || 'Admin'}</span>
            {user?.picture && (
              <img
                src={user.picture}
                alt="Profile"
                className="profile-picture"
                width={50}
                height={50}
              />
            )}
          </div>
        </div>
      </nav>

      <div className="container mt-5">
        {/* Statistics Section */}
        <div className="row mb-5">
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Total Uploaded Images</h5>
                <p className="card-text">{totalUploaded}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Total Processed Images</h5>
                <p className="card-text">{totalProcessed}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Pending Images</h5>
                <p className="card-text">{totalImagesInUploadFolder}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-grid gap-1">
          <button
            className="btn btn-primary"
            onClick={() => navigate('/gallery')}
            style={{ backgroundColor: settings.buttonColor, borderColor: settings.buttonColor }}
          >
            Gallery
          </button>

          <button
            className="btn btn-primary"
            onClick={() => navigate('/admin/settings')}
            style={{ backgroundColor: settings.buttonColor, borderColor: settings.buttonColor }}
          >
            Admin Settings
          </button>
        </div>

        {/* Last Five Uploaded Images with Slide Animation */}
        <div className="card mb-5">
          <div className="card-header">
            <h2 className="card-title mb-4">Last Uploaded Images</h2>
          </div>
          <div className="card-body">
            <div className="sliding-images-wrapper">
              <div className="sliding-images">
                {uploadedImages.slice(0, 10).reverse().map((image, index) => (
                  <div key={index} className="sliding-thumbnail-wrapper">
                    <img src={image} alt={`Uploaded ${index + 1}`} className="sliding-thumbnail" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Chart for Uploaded vs. Processed Images */}
        <div className="card">
          <div className="card-header">
            <h2 className="card-title mb-4">Uploaded vs. Processed Images</h2>
          </div>
          <div className="card-body">
            <Bar data={data} />
          </div>
        </div>
      </div>

      <style jsx>{`
        .admin-dashboard-container {
          min-height: 100vh;
          padding-top: 20px;
        }
        .profile-picture {
          border-radius: 50%;
          object-fit: cover;
        }
        .logout-button {
          height: 40px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .sliding-images-wrapper {
          overflow: hidden;
          width: 100%;
          height: 180px;
          position: relative;
        }
        .sliding-images {
          display: flex;
          animation: slideLeft 20s linear infinite;
        }
        .sliding-thumbnail-wrapper {
          margin-right: 20px;
        }
        .sliding-thumbnail {
          width: 150px;
          height: 150px;
          object-fit: cover;
          border-radius: 10px;
          border: 2px solid ${settings.buttonColor};
        }
        @keyframes slideLeft {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
}
