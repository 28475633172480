// src/pages/Login.js - React login page implementation
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [appId, setAppId] = useState('');
  const [isAppIdVerified, setIsAppIdVerified] = useState(false);
  const [error, setError] = useState('');

  // Function to check if the user is already authenticated
  const checkAuthentication = useCallback(async () => {
    const token = localStorage.getItem('authToken');

    // If there's no token, stop and navigate to login page
    if (!token) {
      return;
    }

    try {
      // Making a request with the token
      const response = await axios.get('/api/user/profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // If response is successful, navigate to admin
      if (response.status === 200) {
        navigate('/admin');
      }
    } catch (error) {
      localStorage.removeItem('authToken');
    }
  }, [navigate]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  // Verify AppID before showing Google Sign-In button
  const handleAppIdVerification = async () => {
    try {
      const response = await axios.post('/api/verify-appid', { appId });
      if (response.status === 200) {
        localStorage.setItem('appIdVerified', 'true');
        setIsAppIdVerified(true);
      }
    } catch (err) {
      setError('Invalid AppID. Please try again.');
    }
  };

  useEffect(() => {
    // Check if AppID is already verified
    const appIdVerified = localStorage.getItem('appIdVerified');
    if (appIdVerified === 'true') {
      setIsAppIdVerified(true);
    }
  }, []);

  // Google Sign-In script and verification
  useEffect(() => {
    if (isAppIdVerified) {
      const handleGoogleSignIn = async (response) => {
        setLoading(true);
        try {
          const googleToken = response.credential;
          const res = await axios.post('/api/auth/google/verify', { token: googleToken });

          if (res.status === 200) {
            localStorage.setItem('authToken', res.data.token); // Save the token received from backend
            navigate('/admin'); // Redirect to admin after successful login
          }
        } catch (error) {
          console.error('Google sign-in verification failed:', error);
          setLoading(false);
          alert('Google Sign-In verification failed. Please try again.');
        }
      };

      const script = document.createElement('script');
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: '1015911375061-m39psv0s2ohu38qmpb5lb23omnlsotil.apps.googleusercontent.com',
          callback: handleGoogleSignIn,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInBtn'),
          {
            theme: 'outline',
            size: 'large',
          }
        );
      };
      document.body.appendChild(script);

      // Clean up the script when component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isAppIdVerified, navigate]);

  return (
    <div className="login-container">
      <div className="card login-card">
        {!isAppIdVerified ? (
          <>
            <h1 className="card-title text-center mb-4">Enter AppID</h1>
            <input
              type="text"
              value={appId}
              onChange={(e) => setAppId(e.target.value)}
              placeholder="Enter your AppID"
              className="form-control mb-3"
            />
            <button className="btn btn-primary w-100" onClick={handleAppIdVerification}>
              Verify AppID
            </button>
            {error && <p className="error-message text-danger text-center mt-3">{error}</p>}
          </>
        ) : (
          <>
            <h1 className="card-title text-center mb-4">Welcome Back, Admin!</h1>
            <p className="card-description text-center mb-4">Sign in to continue to your dashboard.</p>
            <div id="googleSignInBtn"></div>
            {loading && (
              <div id="loadingSpinner" className="text-center mt-4">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <style jsx>{`
        .login-container {
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(135deg, rgb(127, 176, 231), rgb(255, 255, 255));
          font-family: 'Arial', sans-serif;
        }

        .login-card {
          max-width: 400px;
          width: 100%;
          padding: 2rem;
          background: #ffffff;
          border-radius: 15px;
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }

        .login-card:hover {
          transform: translateY(-3px);
          box-shadow: 0 10px 35px rgba(0, 0, 0, 0.25);
        }

        .card-title {
          font-weight: bold;
          font-size: 1.8rem;
          color: #333;
        }

        .card-description {
          font-size: 1.1rem;
          color: #666;
        }

        #googleSignInBtn {
          width: 100%;
          margin-top: 1rem;
        }

        .error-message {
          font-size: 1rem;
        }
      `}</style>
    </div>
  );
}
