// src/UnderConstruction.js
import React from 'react';

const UnderConstruction = () => {
    return (
        <div className="construction-page d-flex flex-column justify-content-center align-items-center vh-100 text-center">
            <img src="construction-image.png" alt="Under Construction" className="construction-image mb-4" />
            <h1>We're Building Something Great!</h1>
            <p>Our site is currently under construction. Stay tuned for updates!</p>
        </div>
    );
};

export default UnderConstruction;