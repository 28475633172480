// src/pages/ApiDoc.js
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// Register JSON language
SyntaxHighlighter.registerLanguage('json', json);

const apiEndpoints = [
    {
        method: 'POST',
        endpoint: '/api/auth/google/verify',
        description: 'Verifies a Google sign-in token provided by the client. This is used to authenticate users using Google OAuth, allowing them access to secure routes if the token is verified successfully.',
        example: {
            url: '/api/auth/google/verify',
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: {
                "token": "<Google Token>"
            },
            response: "200 OK if successful, 401 Unauthorized if verification fails"
        }
    },
    {
        method: 'GET',
        endpoint: '/api/user/profile',
        description: 'Retrieves the profile information of the authenticated user. This includes details such as the user’s name, email, and profile picture. It requires the user to be logged in.',
        example: {
            url: '/api/user/profile',
            method: 'GET',
            response: "JSON with user details or 401 Unauthorized if not logged in"
        }
    },
    {
        method: 'POST',
        endpoint: '/api/upload',
        description: 'Allows users to upload an image file to the server. This endpoint is rate-limited to prevent excessive uploads. The uploaded file is saved on the server for later processing or display.',
        example: {
            url: '/api/upload',
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            body: {
                "photo": "<image file>"
            },
            response: "JSON with success message and filename"
        }
    },
    {
        method: 'GET',
        endpoint: '/api/gallery/data',
        description: 'Fetches lists of uploaded and processed images from the server, providing admins with an overview of the image library.',
        example: {
            url: '/api/gallery/data',
            method: 'GET',
            response: "JSON object with uploaded and processed images arrays"
        }
    },
    {
        method: 'POST',
        endpoint: '/api/settings',
        description: 'Allows admins to update various settings for the application, such as the page title, background color, and other configurable elements.',
        example: {
            url: '/api/settings',
            method: 'POST',
            body: {
                "pageTitle": "Welcome to Photo Upload",
                "pageSubtitle": "Upload and share your favorite moments with just a few clicks.",
                "buttonText": "Upload Your Photo",
                "backgroundColor": "#fafafa",
                "buttonColor": "#4c85c2",
                "textColor": "#333",
                "logoUrl": "https://mazen-dev.com/logos/logo-1731771093269-75730.png",
                "heroSectionColor": "#4271ae"
            },
            response: "Message indicating success or failure"
        }
    },
    {
        method: 'GET',
        endpoint: '/api/settings',
        description: 'Fetches the current admin settings for the application. These settings are used to customize the appearance and behavior of the platform.',
        example: {
            url: '/api/settings',
            method: 'GET',
            response: "JSON object with current settings"
        }
    },
    {
        method: 'POST',
        endpoint: '/api/upload/logo',
        description: 'Handles the upload of a logo image file to the server. The logo is saved in a designated directory and used across the application as the main branding image.',
        example: {
            url: '/api/upload/logo',
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            body: {
                "logo": "<logo image file>"
            },
            response: "JSON with URL of the uploaded logo"
        }
    },
    {
        method: 'POST',
        endpoint: '/api/verify-appid',
        description: 'Verifies if the provided App ID is valid. This is used to control access to certain features, allowing only authorized App IDs to proceed.',
        example: {
            url: '/api/verify-appid',
            method: 'POST',
            body: {
                "appId": "123456"
            },
            response: "200 OK if valid, 401 Unauthorized if invalid"
        }
    },
    {
        method: 'GET',
        endpoint: '/api/logout',
        description: 'Logs out the current user by destroying the session and any associated authentication tokens, ensuring the user’s access to secure routes is revoked.',
        example: {
            url: '/api/logout',
            method: 'GET',
            response: "Message confirming logout"
        }
    },
    {
        method: 'DELETE',
        endpoint: '/api/gallery/image/:filename',
        description: 'Deletes a specific image from the server based on the filename. Only accessible to authenticated admins. Requires confirmation before deleting the file.',
        example: {
            url: '/api/gallery/image/photo-1234567890.jpg',
            method: 'DELETE',
            response: {
                "message": "Image deleted successfully."
            }
        }
    }
];


const ApiDoc = () => {
    useEffect(() => {
        document.title = 'Back-End API';
    }, []);
    return (
        <div className="container mt-5">
            <h1 className="text-center mb-5" style={{ color: '#2a4d69', fontWeight: 'bold' }}>Mazen-Dev API Documentation</h1>
            <div className="row">
                {apiEndpoints.map((api, index) => (
                    <div key={index} className="col-md-12 mb-4">
                        <div className="card shadow-sm" style={{ padding: '20px', borderRadius: '8px' }}>
                            <div className="card-header bg-light d-flex align-items-center" style={{ borderBottom: 'none' }}>
                                <span className={`badge ${api.method === 'GET' ? 'bg-success' : api.method === 'DELETE' ? 'bg-danger' : 'bg-primary'}`} style={{ fontSize: '1rem', padding: '10px 20px', marginRight: '10px' }}>
                                    {api.method}
                                </span>
                                <h5 className="mb-0 text-primary" style={{ fontSize: '1.2rem' }}>{api.endpoint}</h5>
                            </div>
                            <div className="card-body" style={{ paddingTop: '20px' }}>
                                <p><strong>Description:</strong> {api.description}</p>
                                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>Example:</p>
                                <SyntaxHighlighter language="json" style={docco} customStyle={{ padding: '15px', borderRadius: '8px', backgroundColor: '#f9f9f9', textAlign: 'left' }}>
                                    {JSON.stringify(api.example, null, 2)}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ApiDoc;