// src/pages/Gallery.js

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS

export default function Gallery() {
  const navigate = useNavigate();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [processedImages, setProcessedImages] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  // Authentication function
  const authenticateUser = useCallback(async () => {
    try {
      const response = await axios.get('/api/user/profile');
      if (response.status === 200) {
        setUser(response.data);
        loadGalleryImages();
      }
    } catch (error) {
      console.error('User not authenticated:', error);
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    authenticateUser();
  }, [authenticateUser]);

  const loadGalleryImages = async () => {
    try {
      const response = await axios.get('/api/gallery/data');
      if (response.status === 200) {
        setUploadedImages(response.data.uploadedImages);
        setProcessedImages(response.data.processedImages);
      }
    } catch (error) {
      console.error('Failed to fetch gallery data:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.get('/api/logout');
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const showImageInModal = (image) => {
    setSelectedImage(image);
    const modalImage = document.getElementById('modalImage');
    modalImage.src = image;

    const imageModal = new window.bootstrap.Modal(document.getElementById('imageModal'));
    imageModal.show();
  };

  const handleDeleteImage = async () => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      try {
        const filename = selectedImage.split('/').pop(); // Extract the filename from the image URL
        const response = await axios.delete(`/api/gallery/image/${filename}`);
        if (response.status === 200) {
          alert('Image deleted successfully');
          setUploadedImages((prevImages) => prevImages.filter((img) => img !== selectedImage));
          setProcessedImages((prevImages) => prevImages.filter((img) => img !== selectedImage));
          setSelectedImage(null);
          const imageModal = window.bootstrap.Modal.getInstance(document.getElementById('imageModal'));
          imageModal.hide();
        }
      } catch (error) {
        console.error('Failed to delete image:', error);
        alert('Failed to delete image. Please try again.');
      }
    }
  };

  useEffect(() => {
    document.title = 'Gallery';
  }, []);

  useEffect(() => {
    const socket = io('/gallery'); // Connect to the '/gallery' namespace

    socket.on('newImageUploaded', ({ imageUrl }) => {
      setUploadedImages((prevImages) => [imageUrl, ...prevImages]);
    });

    socket.on('imageDeleted', ({ filename }) => {
      setUploadedImages((prevImages) => prevImages.filter((img) => img !== `/uploads/${filename}`));
      setProcessedImages((prevImages) => prevImages.filter((img) => img !== `/uploads/${filename}`));
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="gallery-container">
      {/* Navigation Bar */}
      <nav className="navbar navbar-light bg-light shadow-sm mb-4">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <button className="btn btn-danger logout-button" onClick={handleLogout}>
            Logout
          </button>
          <div className="d-flex align-items-center ms-auto">
            <span className="fw-bold me-2">{user?.name || 'Admin'}</span>
            {user?.picture && (
              <img
                src={user.picture}
                alt="Profile"
                className="profile-picture"
                width={50}
                height={50}
              />
            )}
          </div>
        </div>
      </nav>

      <div className="container">
        <div className="card mb-5">
          <div className="card-header">
            <h2 className="card-title mb-4">Recently Uploaded Images</h2>
          </div>
          <div className="card-body">
            <p className="card-text mb-4">Explore the latest images uploaded by our users. Click on any image to view it in full size.</p>
            <div className="d-flex flex-wrap justify-content-start" id="uploadedImages">
              {uploadedImages.length > 0 ? (
                uploadedImages.map((image, index) => (
                  <div key={index} className="thumbnail-wrapper">
                    <img
                      src={image}
                      alt=""
                      className="thumbnail"
                      loading="lazy"
                      onClick={() => showImageInModal(image)}
                    />
                  </div>
                ))
              ) : (
                <p className="no-images-message">No images uploaded yet.</p>
              )}
            </div>
          </div>
        </div>

        <div className="card text-center">
          <div className="card-header">
            <h2 className="card-title mb-4">Processed Images</h2>
          </div>
          <div className="card-body">
            <p className="card-text mb-4">Below you can find images that have been processed. Click on any image to see the detailed version.</p>
            <div className="d-flex flex-wrap justify-content-start" id="processedImages">
              {processedImages.length > 0 ? (
                processedImages.map((image, index) => (
                  <div key={index} className="thumbnail-wrapper">
                    <img
                      src={image}
                      alt=""
                      className="thumbnail"
                      loading="lazy"
                      onClick={() => showImageInModal(image)}
                    />
                  </div>
                ))
              ) : (
                <p className="no-images-message">No processed images available.</p>
              )}
            </div>
          </div>
          <div className="card-footer text-muted">
            Images requested by API marked as processed.
          </div>
        </div>
      </div>

      {/* Modal for displaying full-size images with delete option */}
      <div className="modal fade" id="imageModal" tabIndex="-1" aria-labelledby="imageModalLabel">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="imageModalLabel">Image Preview</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <img id="modalImage" src={selectedImage} alt="Preview" className="modal-img" />
              <button className="btn btn-danger mt-3" onClick={handleDeleteImage}>
                Delete Image
              </button>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .gallery-container {
          min-height: 100vh;
          padding-top: 20px;
          background: #f8f9fa;
        }
        .profile-picture {
          border-radius: 50%;
          object-fit: cover;
        }
        .thumbnail-wrapper {
          margin: 10px;
          cursor: pointer;
          transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
        }
        .thumbnail-wrapper:hover {
          transform: scale(1.05);
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }
        .thumbnail {
          width: 150px;
          height: 150px;
          border-radius: 10px;
          border: 2px solid #d63384;
          object-fit: cover;
        }
        .logout-button {
          height: 40px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .no-images-message {
          color: #6c757d;
          font-size: 1.2rem;
          text-align: center;
        }
        .modal-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
        
        .container {
          max-width: 800px;
          margin: auto;
          padding: 20px;
          text-align: center;
          background: white;
          border-radius: 15px;
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
          transition: box-shadow 0.3s ease;
        }
      `}</style>
    </div>
  );
}
