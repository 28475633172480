// src/pages/Home.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function UploadImage() {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cooldownTimer, setCooldownTimer] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);

  // State to store settings fetched from the backend
  const [settings, setSettings] = useState({
    pageTitle: 'Welcome to Our Premium Photo Upload Experience',
    pageSubtitle: 'Easily upload and share your favorite moments with just a few clicks.',
    backgroundColor: '#f8f9fa',
    buttonColor: '#d63384',
    buttonText: 'Upload Photo', // Added buttonText
    heroSectionColor: 'linear-gradient(45deg, #d63384, #c12574)', // Added heroSectionColor
    logoUrl: '',
  });

  useEffect(() => {
    document.title = 'MazenDev Image Uploader';
  }, []);

  // Fetch settings from the backend
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/settings');
        if (response.status === 200) {
          setSettings(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    };
    
    fetchSettings();
  }, []);

  // Handle file input change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setUploadStatus('');
    setImagePreview(URL.createObjectURL(selectedFile));
  };

  // Handle upload submission
  const handleUpload = async () => {
    if (!file) {
      setUploadStatus('Please select a file to upload.');
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('photo', file);

    try {
      await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      });

      setUploadStatus('Upload successful!');
      setCooldownTimer(60); // Set cooldown for 60 seconds after successful upload
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadStatus('Error uploading photo.');
    } finally {
      setIsUploading(false);
    }
  };

  // Cooldown timer logic
  useEffect(() => {
    let timer;
    if (cooldownTimer > 0) {
      timer = setInterval(() => {
        setCooldownTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldownTimer]);

  return (
    <div className="home-container" style={{ backgroundColor: settings.backgroundColor }}>
      <div className="container">
        <div
          className="hero-section"
          style={{ background: settings.heroSectionColor }}
        >
          {settings.logoUrl && <img src={settings.logoUrl} alt="Logo" className="logo" />}
          <h1 className="hero-title">{settings.pageTitle}</h1>
          <p className="hero-subtitle">{settings.pageSubtitle}</p>
        </div>

        <div className="upload-section">
          <input type="file" accept="image/*" onChange={handleFileChange} className="form-control mb-3" />
          {imagePreview && (
            <img src={imagePreview} alt="Uploaded Preview" className="thumbnail" />
          )}
          {isUploading && (
            <div className="progress-container mt-3">
              <div
                className="progress-bar progress-bar-custom-pink"
                role="progressbar"
                style={{ width: `${uploadProgress}%`, height: '25px' }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {uploadProgress}%
              </div>
            </div>
          )}
          <button
            className="btn btn-custom-pink mt-3"
            onClick={handleUpload}
            disabled={isUploading || cooldownTimer > 0}
            style={{ backgroundColor: settings.buttonColor }}
          >
            {isUploading ? 'Uploading...' : cooldownTimer > 0 ? `Please wait ${cooldownTimer}s` : settings.buttonText}
          </button>
          {uploadStatus && <p className="upload-status mt-3">{uploadStatus}</p>}
        </div>
      </div>

      <style jsx>{`
        .home-container {
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${settings.backgroundColor};
          font-family: 'Arial', sans-serif;
          color: #333;
          padding: 20px;
        }

        .logo {
          max-width: 150px;
          margin-bottom: 20px;
        }

        .container {
          max-width: 800px;
          margin: auto;
          padding: 20px;
          text-align: center;
          background: white;
          border-radius: 15px;
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
          transition: box-shadow 0.3s ease;
        }

        .container:hover {
          box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
        }

        .hero-section {
          background: ${settings.heroSectionColor};
          padding: 40px;
          border-radius: 15px;
          color: white;
          margin-bottom: 30px;
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
        }

        .hero-title {
          font-size: 2.5rem;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .hero-subtitle {
          font-size: 1.3rem;
        }

        .upload-section {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .btn-custom-pink {
          border: none;
          color: white;
          font-size: 1.1rem;
          padding: 10px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          text-align: center;
          border-radius: 5px;
        }

        .btn-custom-pink:hover {
          transform: translateY(-3px);
          box-shadow: 0 6px 15px rgba(214, 51, 132, 0.4);
        }

        .thumbnail {
          max-width: 60%;
          height: auto;
          margin: 20px auto;
          border-radius: 10px;
          border: 2px solid #d63384;
          display: block;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }

        .thumbnail:hover {
          transform: scale(1.05);
          box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
        }

        .progress-container {
          width: 100%;
          background-color: #e9ecef;
          border-radius: 5px;
          overflow: hidden;
        }

        .progress-bar-custom-pink {
          background-color: #d63384;
          font-weight: bold;
          color: white;
          line-height: 25px;
        }

        .upload-status {
          font-size: 16px;
          color: #007bff;
        }
      `}</style>
    </div>
  );
}
